<template>
  <div
    v-motion
    class="forth-section"
    :initial="{
      opacity: 0,
      y: 100,
    }"
    :visibleOnce="{
      opacity: 1,
      y: 0,
      transition: {
        delay: 100,
        stiffness: 200,
        damping: 90,
        mass: 3,
      },
    }"
  >
    <div class="methods">
      <div class="payments">
        <img src="img/recurring-payments.svg" class="payment-icon" alt="" />
        <h4>Recurring Payments</h4>
        <p>
          Grow revenues with automated recurring payments via credit/debit card,
          e-wallets, and bank transfers.
        </p>
      </div>
      <div class="payments">
        <img
          src="img/automated-confirmations.svg"
          class="payment-icon"
          alt=""
        />
        <h4>Automated Confirmations</h4>
        <p>
          Automated notifications for a smooth customer experience and optimized
          revenue retention.
        </p>
      </div>
      <div class="payments">
        <img
          src="img/business-dashboard.svg"
          class="payment-icon dashboard-icon"
          alt=""
        />
        <h4>Business Dashboards</h4>
        <p>
          Track all your subscriptions with simple powerful tools to watch your
          business grow.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.forth-section {
  width: 100%;
}

.methods {
  width: 71rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  padding-top: 3.5rem;
  padding-bottom: 2rem;
}

.payments {
  align-items: center;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  margin: 20px auto;
  padding: 20px;
  width: 100%;
}

.payments:nth-child(2) img {
  width: 5.7rem;
}

.payments h4 {
  margin-top: 1rem;
  font-weight: 700;
  font-size: 1.2rem;
}

.payments p {
  margin-top: 1rem;
  line-height: 2;
  font-weight: 500;
  opacity: 0.6;
  font-size: 1rem;
  padding: 1rem;
}

.payment-icon {
  width: 6rem;
}

.dashboard-icon {
  width: 7rem;
}

@media only screen and (max-width: 1400px) {
  .methods {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .methods {
    width: 40rem;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

@media only screen and (max-width: 800px) {
  .methods {
    width: 30rem;
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 650px) {
  .methods {
    width: 20rem;
    grid-template-columns: 1fr;
  }

  .payments h4,
  .payments p {
    font-size: 1rem;
  }
}
</style>
